import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import { ProtectedRoute } from './ProtectedRoute';
import Influencers from 'views/influencers/Influencers';
import InfluencerReferrals from 'views/influencers/InfluencerReferrals';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
        // try to import the component
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                    return window.location.reload(); // refresh the page
                }
                reject(error); // Default error behaviour as already tried refresh
            });
    });
};

// sample page routing
const Raffles = Loadable(lazy(() => import('views/raffles/Raffles')));
const RaffleEntries = Loadable(lazy(() => import('views/raffles/RaffleEntries')));
const Plans = Loadable(lazy(() => import('views/plans/Plans')));
const ViewPlans = Loadable(lazy(() => import('views/plans/ViewPlans')));
const Receipts = Loadable(lazy(() => import('views/receipts/Receipts')));
const Users = Loadable(lazy(() => import('views/users/Users')));
// const Influencers = Loadable(lazy(() => import('views/influencers/Influencers')));
// const InfluencerReferrals = Loadable(lazy(() => import('views/influencers/InfluencerReferrals')));
// const InfluencerReferrals = lazy(() => lazyRetry(() => import('views/influencers/InfluencerReferrals')));

console.log('===> in MainRoutes...');

const MainRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MainLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/default',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'raffles',
            element: <Raffles />
        },
        {
            path: 'view-plans',
            element: <ViewPlans />
        },
        {
            path: 'view-raffles/:id',
            element: <RaffleEntries />
        },
        {
            path: 'plans',
            element: <Plans />
        },
        {
            path: 'receipts',
            element: <Receipts />
        },
        {
            path: 'users',
            element: <Users />
        },
        {
            path: 'influencers',
            element: <Influencers />
        },
        {
            path: 'influencer/:id',
            element: (
                <>
                    {console.log('--> in MainRoutes, before calling InfluencerReferrals')}
                    <InfluencerReferrals />
                </>
            )
        }
    ]
};

export default MainRoutes;

import axios from 'axios';

const baseURLGeneral = process.env.REACT_APP_API_URL.slice(0, -6);

const dataServer = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: 'http://localhost:4400/app/api/admin',
    timeout: 100000000,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

const dataServerGeneral = axios.create({
    baseURL: baseURLGeneral,
    timeout: 100000000,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

dataServer.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem('user');
            window.location.replace = '/login';
            window.location.reload();
        } else {
            return Promise.reject(error);
        }
    }
);

export { dataServer, dataServerGeneral };

import React from 'react';
import MUIDataTable from 'mui-datatables';

import { useParams } from 'react-router-dom';

import Modal from 'react-modal';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import moment from 'moment';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';

import BeatLoader from 'react-spinners/BeatLoader';

// assets
import { viewUsersReferred } from 'services/api/influencers';

const getMuiTheme = () =>
    createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#7986cb', contrastText: '#212121' }
        },
        components: {
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#7986cb'
                    }
                }
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    actions: {
                        display: 'flex',
                        flex: 'initial',
                        '& > span, & > button': {
                            order: 99
                        },
                        '& > span:last-child, & > button:last-child': {
                            order: 1
                        },
                        '& > span:nth-child(4), & > button:nth-child(4)': {
                            order: 2
                        }
                    }
                }
            }
        }
    });

Modal.setAppElement('#root');

const options = {
    filterType: 'dropdown',
    selectableRows: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    jumpToPage: true,
    downloadOptions: {
        filename: 'Referred Users.csv'
    }
};

const InfluencerReferrals = ({ ...others }) => {
    console.log('--> in  InfluencerReferrals...');
    const [load, setLoad] = React.useState(false);
    const [influencerReferrals, setInfluencerReferrals] = React.useState([]);
    const { id } = useParams();

    const columns = [
        {
            name: 'no',
            label: 'No.',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return tableMeta.rowIndex + 1;
                }
            }
        },
        {
            name: 'fullName',
            label: 'Name',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: `pricing_plan.name`,
            label: 'Membership Type',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'id',
            label: 'User ID',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'isVerified',
            label: 'Is User Verified',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = value === 1 ? 'Verified' : 'Not Verified';
                    return <>{status}</>;
                }
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'createdAt',
            label: 'Created At',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const date = value;
                    const newDate = moment(date).format('MM-DD-YYYY');
                    return <div>{newDate}</div>;
                }
            }
        }
    ];

    const fetchInfluencerReferrals = async (id) => {
        try {
            const res = await viewUsersReferred(id);
            console.log('Users data is : ', res.data.usersDetail);
            setInfluencerReferrals(res.data.usersDetail);
            setLoad(false);
        } catch (err) {
            setLoad(false);
            console.log('Error: ', err);
        }
    };

    React.useEffect(() => {
        setLoad(true);
        fetchInfluencerReferrals(id);
    }, []);

    return (
        <MainCard title="Referred Users">
            {load ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <BeatLoader color="#e1c318" />
                    </div>
                </>
            ) : (
                <>
                    <div style={{ height: 'auto', width: '100%' }}>
                        <ThemeProvider theme={getMuiTheme}>
                            <MUIDataTable data={influencerReferrals} columns={columns} options={options} />
                        </ThemeProvider>
                    </div>
                </>
            )}
        </MainCard>
    );
};

export default InfluencerReferrals;

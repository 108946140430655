// material-ui
import { useTheme } from '@mui/material/styles';

// import NewLogo from '../assets/images/new-logo.svg';
import NewLogo from 'assets/jsIcons/newLogo';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    // return <img src={NewLogo} style={{ height: '65px', width: '100%' }} />;
    return <NewLogo />;
};

export default Logo;

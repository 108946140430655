import { dataServer } from './axios.config';

export const SignUp = (user) => {
    var countryCode = user.countryCode;
    var phoneNo = user.phoneNo;
    var fullName = user.fullName;
    var password = user.password;
    var data = { countryCode, phoneNo, fullName, password };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/sign-up', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const AccountCheck = (data) => {
    var otp = data.otp;

    var data = { otp };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/verify', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const LogOut = () => {
    localStorage.removeItem('user');
};

export const SignIn = (user) => {
    var countryCode = user.countryCode;
    var phoneNo = user.phoneNo;
    var password = user.password;

    var data = { countryCode, phoneNo, password };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/login', data, {
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Access-Control-Allow-Origin': '*'
                // }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const ForgotPassword = (user) => {
    var countryCode = user.countryCode;
    var phoneNo = user.phoneNo;

    var data = { countryCode, phoneNo };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/forgot-password', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const VerifyOtp = (user) => {
    let verificationToken = user.otp;

    var data = { verificationToken };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/checkotp', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const ResetPassword = (data) => {
    var password = data.password;
    var confirmPassword = data.confirmPassword;
    var verificationToken = data.verificationCode;

    var data = { password, confirmPassword, verificationToken };

    return new Promise((resolve, reject) => {
        dataServer
            .post('/reset-password', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const resendOpt = (user) => {
    var countryCode = user.countryCode;
    var phoneNo = user.phoneNo;
    var password = user.password;

    var data = { countryCode, phoneNo };

    return new Promise((resolve, reject) => {
        dataServer
            .patch('/resendotp', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const VerifyAccount = Loadable(lazy(() => import('views/pages/authentication/authentication/VerifyAccount')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const PhoneVerify = Loadable(lazy(() => import('views/pages/authentication/authentication/PhoneVerify')));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/phoneverify',
            element: <PhoneVerify />
        },
        {
            path: '/forgotpass',
            element: <ForgotPassword />
        },
        {
            path: '/verifyotp',
            element: <VerifyAccount />
        },
        {
            path: '/resetpass',
            element: <ResetPassword />
        }
    ]
};

export default AuthenticationRoutes;

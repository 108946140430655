import React from 'react';

const AdminIcon = () => {
    return (
        <>
            {/* <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 145 145"
                style="enable-background:new 0 0 145 145;"
                xml:space="preserve"
            > */}
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 145 145">
                <g id="men_4">
                    <rect style={{ fill: '#F2A88F' }} width="145" height="145" />
                    <g>
                        <g>
                            <ellipse style={{ fill: '#3C2A20' }} cx="71.516" cy="26.72" rx="5.26" ry="5.598" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="79.144" cy="27.765" rx="5.261" ry="5.598" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="93.707" cy="33.363" rx="5.262" ry="5.599" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="99.661" cy="42.892" rx="5.261" ry="5.598" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="102.379" cy="50.705" rx="5.26" ry="5.599" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="62.617" cy="26.842" rx="4.449" ry="4.734" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="103.417" cy="58.759" rx="4.448" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="103.417" cy="67.061" rx="4.448" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="98.967" cy="72.78" rx="4.449" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="42.103" cy="63.494" rx="4.448" ry="4.734" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="43.604" cy="71.796" rx="4.45" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="45.916" cy="75.116" rx="4.449" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="87.409" cy="26.903" rx="4.45" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="97.118" cy="40.433" rx="4.45" ry="4.735" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="56.434" cy="27.765" rx="4.393" ry="4.674" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="54.238" cy="31.577" rx="4.393" ry="4.674" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="44.642" cy="39.821" rx="4.966" ry="5.285" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="42.103" cy="47.566" rx="4.394" ry="4.674" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="41.523" cy="56.051" rx="4.393" ry="4.674" />
                            <ellipse style={{ fill: '#3C2A20' }} cx="47.996" cy="32.439" rx="4.393" ry="4.674" />
                            <path
                                style={{ fill: '#3C2A20' }}
                                d="M49.189,79.3l4.299,1.472c0,0-4.58-12.17-2.488-18.446c2.09-6.275,5.738-17.438,11.166-16.331
				c5.43,1.107,13.742,2.615,20.104-0.076c3.477-1.031,10.518,4.503,9.838,11.053s4.354,14.083-0.508,22.323
				c5.088-1.23,10.01-3.32,10.01-3.32l1.807-17.216L101.61,45.18L90.92,27.765l-14.615-0.862L61.631,26.72L51,31.577l-5.654,10.268
				l-3.281,13.099l2.828,14.574L49.189,79.3z"
                            />
                            <path
                                style={{ fill: '#3C2A20' }}
                                d="M49.189,79.3l4.299,1.472c0,0-4.58-12.17-2.488-18.446c2.09-6.275,5.738-17.438,11.166-16.331
				c5.43,1.107,13.742,2.615,20.104-0.076c3.477-1.031,10.518,4.503,9.838,11.053s4.354,14.083-0.508,22.323
				c5.088-1.23,10.01-3.32,10.01-3.32l1.807-17.216L101.61,45.18L90.92,27.765l-14.615-0.862L61.631,26.72L51,31.577l-5.654,10.268
				l-3.281,13.099l2.828,14.574L49.189,79.3z"
                            />
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        style={{ fill: '#F1C9A5' }}
                                        d="M109.375,115.394c-4.965-9.396-36.875-15.291-36.875-15.291s-31.91,5.896-36.875,15.291
						C31.957,128.433,28.889,145,28.889,145H72.5h43.611C116.111,145,114.039,127.236,109.375,115.394z"
                                    />
                                    <path
                                        style={{ fill: '#E4B692' }}
                                        d="M72.5,100.103c0,0,31.91,5.896,36.875,15.291c4.664,11.842,6.736,29.606,6.736,29.606H72.5
						V100.103z"
                                    />
                                    <rect x="63.813" y="81.001" style={{ fill: '#F1C9A5' }} width="17.373" height="29.077" />
                                    <rect x="72.5" y="81.001" style={{ fill: '#E4B692' }} width="8.686" height="29.077" />
                                    <path
                                        style={{ opacity: '0.1', fill: '#DDAC8C', enableBackground: 'new' }}
                                        d="M63.813,94.475c1.563,4.485,7.869,7.056,12.5,7.056
						c1.674,0,3.305-0.28,4.873-0.794V81.001H63.813V94.475z"
                                    />
                                    <path
                                        style={{ fill: '#FFFFFF' }}
                                        d="M109.375,115.394c-2.9-5.487-14.98-9.777-24.537-12.398c-0.373,6.5-5.746,11.658-12.338,11.658
						c-6.594,0-11.967-5.159-12.34-11.658c-9.555,2.622-21.637,6.912-24.535,12.398C31.957,128.433,28.889,145,28.889,145H72.5
						h43.611C116.111,145,114.039,127.236,109.375,115.394z"
                                    />
                                    <path
                                        style={{ fill: '#F1C9A5' }}
                                        d="M94.838,62.653c0-18.162-10.002-28.489-22.338-28.489S50.162,44.492,50.162,62.653
						c0,7.609,2.328,24.455,6.301,27.889c2.728,2.425,11.635,6.577,15.992,6.577l0,0c0.014,0,0.031-0.002,0.045-0.002
						c0.016,0,0.031,0.002,0.043,0.002h0.002c4.357,0,13.264-4.152,15.992-6.577C92.668,86.178,94.838,69.637,94.838,62.653z"
                                    />
                                    <path
                                        style={{ fill: '#F1C9A5' }}
                                        d="M94.838,62.653c0-18.162-10.002-28.489-22.338-28.489S50.162,44.492,50.162,62.653
						c0,7.609,2.328,24.455,6.301,27.889c2.728,2.425,11.635,6.577,15.992,6.577l0,0c0.014,0,0.031-0.002,0.045-0.002
						c0.016,0,0.031,0.002,0.043,0.002h0.002c4.357,0,13.264-4.152,15.992-6.577C92.668,86.178,94.838,69.637,94.838,62.653z"
                                    />
                                    <path
                                        style={{ fill: '#E4B692' }}
                                        d="M72.5,97.116c0.016,0,0.031,0.002,0.043,0.002h0.002c4.357,0,13.264-4.152,15.992-6.577
						c4.131-4.364,6.301-20.905,6.301-27.889c0-18.162-10.002-28.489-22.338-28.489V97.116L72.5,97.116z"
                                    />
                                    <path
                                        style={{ fill: '#E4B692' }}
                                        d="M91.438,75.247c-4.049-0.424-6.783-4.787-6.098-9.74c0.676-4.957,4.512-8.637,8.562-8.215
						c4.047,0.421,6.777,4.782,6.094,9.739C99.316,71.988,95.486,75.666,91.438,75.247z"
                                    />
                                    <path
                                        style={{ fill: '#F1C9A5' }}
                                        d="M45.16,67.031c-0.684-4.957,2.047-9.318,6.092-9.739c4.053-0.422,7.887,3.258,8.566,8.215
						c0.684,4.953-2.053,9.316-6.1,9.74C49.67,75.666,45.84,71.988,45.16,67.031z"
                                    />
                                </g>
                            </g>
                        </g>
                        <path
                            d="M93.975,59.211c-0.01-0.303-0.041-0.604-0.059-0.906c-0.01-0.136-0.076-0.222-0.207-0.256
			c-0.096-0.023-0.186-0.05-0.279-0.063c-1.338-0.21-2.682-0.415-4.033-0.511c-1.082-0.074-2.162-0.123-3.244-0.17
			c-2.084-0.093-4.166-0.048-6.238,0.214c-1.264,0.161-2.519,0.389-3.734,0.782c-0.617,0.201-1.223,0.432-1.838,0.627
			c-0.963,0.305-1.949,0.442-2.947,0.255c-0.598-0.112-1.191-0.284-1.773-0.471c-1.051-0.342-2.105-0.677-3.195-0.879
			c-2.123-0.391-4.264-0.572-6.26-0.551c-1.625,0.003-3.086,0.059-4.541,0.233c-1.191,0.142-2.383,0.302-3.576,0.465
			c-0.252,0.034-0.5,0.108-0.746,0.174c-0.133,0.034-0.221,0.123-0.225,0.27c-0.023,0.704-0.049,1.407-0.066,2.112
			c-0.002,0.107,0.023,0.213,0.039,0.318c0.014,0.09,0.063,0.153,0.148,0.189c0.092,0.04,0.188,0.079,0.266,0.136
			c0.393,0.272,0.58,0.685,0.728,1.114c0.108,0.305,0.178,0.624,0.264,0.936c0.057,0.194,0.131,0.386,0.174,0.582
			c0.205,0.917,0.398,1.84,0.609,2.757c0.203,0.875,0.465,1.731,0.883,2.531c0.467,0.894,1.133,1.58,2.08,1.974
			c0.572,0.238,1.168,0.39,1.779,0.486c1.092,0.173,2.193,0.196,3.299,0.115c1.117-0.08,2.219-0.25,3.295-0.576
			c0.799-0.24,1.553-0.578,2.193-1.123c0.977-0.833,1.764-1.823,2.367-2.959c0.607-1.131,1.027-2.338,1.408-3.56
			c0.111-0.352,0.215-0.704,0.342-1.049c0.188-0.508,0.549-0.822,1.092-0.906c0.172-0.025,0.352-0.033,0.525-0.022
			c0.406,0.022,0.797,0.109,1.068,0.448c0.117,0.148,0.227,0.312,0.303,0.483c0.148,0.336,0.287,0.677,0.398,1.027
			c0.478,1.51,1.109,2.95,1.91,4.317c0.664,1.135,1.541,2.053,2.701,2.687c0.822,0.447,1.697,0.734,2.613,0.911
			c1.377,0.267,2.766,0.332,4.162,0.272c0.844-0.037,1.678-0.144,2.49-0.388c0.832-0.249,1.564-0.655,2.113-1.352
			c0.356-0.448,0.617-0.947,0.834-1.475c0.453-1.107,0.746-2.266,1.01-3.432c0.129-0.57,0.264-1.141,0.404-1.709
			c0.072-0.295,0.164-0.589,0.244-0.883c0.078-0.276,0.145-0.556,0.318-0.794c0.174-0.239,0.355-0.476,0.646-0.578
			c0.129-0.048,0.195-0.137,0.221-0.257c0.021-0.093,0.045-0.188,0.045-0.282C93.986,60.053,93.986,59.63,93.975,59.211z
			 M92.166,59.638c-0.457,0-0.826-0.091-0.826-0.202c0-0.113,0.369-0.205,0.826-0.205c0.451,0,0.82,0.092,0.82,0.205
			C92.986,59.547,92.617,59.638,92.166,59.638z"
                        />
                        <path
                            style={{ fill: '#3C2A20' }}
                            d="M101.609,45.18L90.92,27.765l-14.615-0.862L61.631,26.72L51,31.577l-5.654,10.268l-3.281,13.099
			l2.828,14.574l1.023,10.333l8.322,7.094c0,0-1.664-5.73-2.768-11.881c-3.135-0.833-5.76-4.011-6.311-8.033
			c-0.684-4.957,2.047-9.318,6.092-9.739c0.5-0.052,0.994-0.041,1.482,0.027c0.424-5.942,3.143-13.124,9.432-11.323
			c5.484,1.57,13.742,2.615,20.104-0.076c5.139-2.393,10.518,4.503,9.838,11.053c-0.014,0.128-0.014,0.278-0.025,0.412
			c0.598-0.115,1.205-0.156,1.82-0.092c4.047,0.421,6.777,4.782,6.094,9.739c-0.594,4.336-3.6,7.693-7.053,8.185
			c-0.066,4.093-0.66,8.05-2.475,11.124c5.09-1.23,11.141-10.365,11.141-10.365l1.807-17.216L101.609,45.18z"
                        />
                    </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
            </svg>
        </>
    );
};

export default AdminIcon;

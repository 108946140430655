import { dataServer } from './axios.config';

const user = JSON.parse(localStorage.getItem('user'));

export const viewAllInfluencers = () => {
    var accessToken = user.token;
    console.log('Here-----viewAllInfluencers....---...');
    return new Promise((resolve, reject) => {
        dataServer
            .get('/view-all-influencers', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const viewUsersReferred = (id) => {
    var accessToken = user.token;
    console.log('Here-----viewUsersReferred....---...');
    return new Promise((resolve, reject) => {
        dataServer
            .get(`/view-users-referred/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const toggleInfluencerStatus = (id) => {
    var accessToken = user.token;
    console.log('Here-----toggleInfluencerStatus....---...');
    return new Promise((resolve, reject) => {
        dataServer
            .patch(
                `/toggle-influencer-status/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const assignUserCode = (id) => {
    var accessToken = user.token;
    console.log('===> Here-----assignUserCode....---...');
    console.log(`Id is ${id}`);
    return new Promise((resolve, reject) => {
        dataServer
            .patch(
                `/assign-user-code/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const assignInfluencerCode = (id, promoCode) => {
    var accessToken = user.token;
    console.log('Here-----assignInfluencerCode....---...');
    console.log(`Id is ${id} and promo code is: ${promoCode}`);
    return new Promise((resolve, reject) => {
        dataServer
            .patch(
                `/assign-influencer-code/${id}`,
                { promoCode },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const softDeleteInfluencer = (id) => {
    var accessToken = user.token;
    console.log('Here-----softDeleteInfluencer....---...');
    // return new Promise((resolve, reject) => {
    //     dataServer
    //         .delete(`/softdeleteinfluencer/${id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`
    //             }
    //         })
    //         .then((res) => resolve(res))
    //         .catch((err) => reject(err));
    // });
};

export const suspendInfluencer = (id) => {
    var accessToken = user.token;
    console.log('Here-----suspendInfluencer....---...');
    // return new Promise((resolve, reject) => {
    //     dataServer
    //         .patch(
    //             `/suspendinfluencer/${id}`,
    //             {},
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`
    //                 }
    //             }
    //         )
    //         .then((res) => resolve(res))
    //         .catch((err) => reject(err));
    // });
};

export const recordPayment = (id, payments) => {
    var accessToken = user.token;
    console.log('Here-----Record Payment....---...');

    return new Promise((resolve, reject) => {
        dataServer
            .patch(
                `/record-payments`,
                {
                    payments,
                    id
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const awardTickets = (id, tickets) => {
    var accessToken = user.token;
    console.log('Here-----awardTickets---...');

    return new Promise((resolve, reject) => {
        dataServer
            .patch(
                `/awardtickets`,
                {
                    tickets,
                    id
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

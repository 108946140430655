// assets
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const other = {
    id: 'sample-docs-roadmap',
    // title: 'Pages',
    type: 'group',
    children: [
        {
            id: 'raffles',
            title: 'Raffles',
            type: 'item',
            url: '/raffles',
            icon: LocalActivityOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'viewplans',
            title: 'View Plans',
            type: 'item',
            url: '/view-plans',
            icon: PaymentsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'plans',
            title: 'Plans',
            type: 'item',
            url: '/plans',
            icon: AddCardOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'receipts',
            title: 'Receipts',
            type: 'item',
            url: '/receipts',
            icon: ReceiptOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'influencers',
            title: 'Influencers',
            type: 'item',
            url: '/influencers',
            icon: CampaignOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: PeopleAltOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default other;

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// chart constants
export const getChartOptions = (view) => {
    const commonOptions = {
        chartArea: { width: '67%', height: '77.5%' },
        colors: ['#3b3b6d', '#b32134', '#fff', '#CFD11A', '#808F85', '#BFDBF7', 'E1E5F2', '#FCB5B5'],
        hAxis: {
            title: view === 'week' ? 'Week' : 'Month',
            textStyle: { color: '#fff' },
            titleTextStyle: { color: '#fff' }
        },
        vAxis: {
            title: 'No. of Users',
            minValue: 0,
            textStyle: { color: '#fff' },
            titleTextStyle: { color: '#fff' }
        },
        backgroundColor: '#383d3e',
        textStyle: {
            color: '#fff'
        },
        legend: {
            position: 'top-right',
            textStyle: { color: '#fff' }
        },
        animation: {
            duration: 1000,
            startup: true,
            hideDataColumns: true,
            initialDataDelay: 500
        }
    };

    const getUpdatedOptions = (legends) => {
        const colorArray = legends.map((legend, index) => {
            const colors = ['#3b3b6d', '#b32134', '#fff', '#CFD11A', '#808F85', '#BFDBF7', 'E1E5F2', '#FCB5B5'];
            return colors[index % colors.length];
        });

        return {
            ...commonOptions,
            colors: colorArray
        };
    };

    return {
        commonOptions,
        getUpdatedOptions
    };
};

export const getIncomeChartOptions = (view) => {
    const commonOptions = {
        chartArea: { width: '75%', height: '77.5%' },
        colors: ['#CFD11A'],
        hAxis: {
            title: view === 'week' ? 'Week' : 'Month',
            textStyle: { color: '#fff' },
            titleTextStyle: { color: '#fff' }
        },
        vAxis: {
            title: 'Income',
            minValue: 0,
            textStyle: { color: '#fff' },
            titleTextStyle: { color: '#fff' }
        },
        backgroundColor: '#383d3e',
        textStyle: {
            color: '#fff'
        },
        legend: 'none',
        animation: {
            duration: 1000,
            startup: true,
            hideDataColumns: true,
            initialDataDelay: 500
        }
    };

    return commonOptions;
};

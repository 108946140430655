import React from 'react';
import MUIDataTable from 'mui-datatables';

import { Link, Navigate, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

import TextField from '@mui/material/TextField';

import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/RemoveRedEye';

import { pink, green, blue } from '@mui/material/colors';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useTheme } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import * as Yup from 'yup';
import { Formik } from 'formik';

import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BeatLoader from 'react-spinners/BeatLoader';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    viewAllInfluencers,
    softDeleteInfluencer,
    toggleInfluencerStatus,
    assignUserCode,
    suspendInfluencer,
    recordPayment,
    awardTickets
} from 'services/api/influencers';

const suspendSuccess = () => toast.success('Suspended Successfully');

const deleteSuccess = () => toast.success('Deleted Successfully');

const revertSuccess = () => toast.success('Reverted to User Successfully');

const codeSuccess = () => toast.success('Referral Code assigned to User Successfully');

const RecordPaymentSuccess = () => toast.success('Payment recorded Successfully');

const AwardTicketsSuccess = () => toast.success('updated Tickets Successfully');

const reqFailed = (msg) => toast.warning(msg);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',

        overflowY: 'hidden',
        backgroundColor: '#fff',
        border: 'none',
        color: 'black'
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.35)'
    }
};

const getMuiTheme = () =>
    createTheme({
        palette: {
            mode: 'dark',
            primary: { main: '#7986cb', contrastText: '#212121' }
        },
        components: {
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#7986cb'
                    }
                }
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    filterPaper: {
                        width: '400px !important'
                    },
                    actions: {
                        display: 'flex',
                        flex: 'initial',
                        '& > span, & > button': {
                            order: 99
                        },
                        '& > span:last-child, & > button:last-child': {
                            order: 1
                        },
                        '& > span:nth-child(4), & > button:nth-child(4)': {
                            order: 2
                        }
                    }
                }
            }
        }
    });

Modal.setAppElement('#root');

const options = {
    filterType: 'dropdown',
    selectableRows: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    jumpToPage: true,
    downloadOptions: {
        filename: 'Influencers.csv'
    }
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const Influencers = ({ ...others }) => {
    const theme = useTheme();

    const [load, setLoad] = React.useState(false);

    const [allInfluencers, setAllInfluencers] = React.useState([]);
    const [usermodalIsOpen, setUserIsOpen] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [paymentIsOpen, setPaymentIsOpen] = React.useState(false);
    const [deletemodalIsOpen, setdeleteIsOpen] = React.useState(false);
    const [payment, setPayment] = React.useState(undefined);
    const [tickets, setTickets] = React.useState(undefined);
    const [id, setId] = React.useState(undefined);
    const [startValue, setStartValue] = React.useState('01-01-2023');
    const [endValue, setEndValue] = React.useState('02-02-2023');

    const navigate = useNavigate();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    function openPaymentModal() {
        setPaymentIsOpen(true);
    }

    function closePaymentModal() {
        setPaymentIsOpen(false);
    }

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleDelete = async () => {
        try {
            setLoad(true);
            setdeleteIsOpen(false);
            if (id !== undefined) {
                const res = await softDeleteInfluencer(id);
                console.log('res: ', res);
                if (res.status === 200) {
                    deleteSuccess();
                    fetchInfluencers();
                    setLoad(false);
                }
            }
        } catch (err) {
            setLoad(false);
            console.log('Error: ', err.response.data.messsage);
            reqFailed(err.response.data.messsage);
        }
    };

    const [checked, setChecked] = React.useState(true);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleStartChange = (start) => {
        setStartValue(start);
    };

    const handleEndChange = (end) => {
        setEndValue(end);
    };

    const handleMakeUser = async () => {
        try {
            setLoad(true);
            setUserIsOpen(false);
            if (id !== undefined) {
                const res = await toggleInfluencerStatus(id);
                console.log('res: ', res);
                if (res.status === 200) {
                    revertSuccess();
                    fetchInfluencers();
                    const res2 = await assignUserCode(id);
                    if (res2.status === 200) {
                        codeSuccess();
                    }
                    setLoad(false);
                }
            }
        } catch (err) {
            setLoad(false);
            console.log('Error: ', err);
            reqFailed(err);
        }
    };

    const fetchInfluencers = async () => {
        try {
            const res = await viewAllInfluencers();
            console.log('Influencers data is : ', res.data.users);
            setAllInfluencers(res.data.users);
            setLoad(false);
        } catch (err) {
            setLoad(false);
            reqFailed(err.response.data.message);
            console.log('Error: ', err);
        }
    };

    const handleAssign = async () => {
        try {
            setLoad(true);
            setIsOpen(false);
            console.log('Id is :', id);

            if (tickets != undefined && id != undefined) {
                const res = await awardTickets(id, tickets);
                if (res.status === 200) {
                    AwardTicketsSuccess();
                    fetchInfluencers();
                    setTickets(0);
                    setLoad(false);
                    setTickets(undefined);
                    setId(undefined);
                }
            }
            setLoad(false);
        } catch (err) {
            setLoad(false);
            console.log(err);
            console.log('Error: ', err.messsage);
            reqFailed(err.message);
        }
    };

    const handlePayment = async () => {
        console.log('handlePayment called');
        try {
            setLoad(true);
            setPaymentIsOpen(false);
            console.log('Id is :', id);

            if (payment != undefined && id != undefined) {
                const res = await recordPayment(id, payment);
                if (res.status === 200) {
                    RecordPaymentSuccess();
                    fetchInfluencers();
                    setPayment(0);
                    setLoad(false);
                    setPayment(undefined);
                    setId(undefined);
                }
            }
            setLoad(false);
        } catch (err) {
            setLoad(false);
            console.log(err);
            console.log('Error: ', err.messsage);
            reqFailed(err.message);
        }
    };

    React.useEffect(() => {
        setLoad(true);
        fetchInfluencers();
    }, []);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'fullName',
            label: 'Name',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'tickets',
            label: 'Tickets',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'payments',
            label: 'Payments',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <span>$ {value}</span>
            }
        },
        {
            name: 'referralCount',
            label: 'Referrals',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    if (value === 0) {
                        return <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{value}</span>;
                    } else {
                        return (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span style={{ marginRight: '5px' }}>{value}</span>
                                    <ViewIcon
                                        style={{ cursor: 'pointer', textAlign: 'center' }}
                                        onClick={async () => {
                                            setLoad(true);
                                            const id = tableMeta.rowData[0];
                                            navigate(`/influencer/${id}`);
                                        }}
                                        sx={{ color: green[500] }}
                                    />
                                </div>
                            </>
                        );
                    }
                }
            }
        },
        {
            name: 'recordPayment',
            label: 'Record Payment',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button
                                onClick={() => {
                                    setPaymentIsOpen(true);
                                    setId(tableMeta.rowData[0]);
                                }}
                            >
                                Record Payment
                            </Button>
                        </>
                    );
                }
            }
        },
        {
            name: 'awardTickets',
            label: 'Award Ticket',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button
                                onClick={() => {
                                    setIsOpen(true);
                                    setId(tableMeta.rowData[0]);
                                }}
                            >
                                Award Tickets
                            </Button>
                        </>
                    );
                }
            }
        },
        {
            name: 'countryCode',
            label: 'Country Code',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'phoneNo',
            label: 'Phone No',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'isVerified',
            label: 'Is Verified',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = value === 1 ? 'Verified' : 'Not Verified';
                    return <>{status}</>;
                }
            }
        },
        {
            name: 'promoCode',
            label: 'Referral Code',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'Delete',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <AnimateButton>
                            <Grid item xs={8}>
                                <Button
                                    style={{ width: '5%', textAlign: 'center' }}
                                    disableElevation
                                    fullWidth
                                    onClick={async () => {
                                        setdeleteIsOpen(true);
                                        setId(tableMeta.rowData[0]);
                                    }}
                                    size="small"
                                    type="submit"
                                    variant="text"
                                    sx={{ color: pink[500] }}
                                >
                                    Delete
                                </Button>
                            </Grid>
                            <ToastContainer />
                        </AnimateButton>
                    );
                }
            }
        },
        {
            name: 'User',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <AnimateButton>
                            <Grid item xs={8}>
                                <Button
                                    style={{ width: '5%', textAlign: 'center' }}
                                    disableElevation
                                    fullWidth
                                    onClick={async () => {
                                        setUserIsOpen(true);
                                        setId(tableMeta.rowData[0]);
                                    }}
                                    size="small"
                                    type="submit"
                                    variant="text"
                                    sx={{ color: blue[500] }}
                                >
                                    Make User
                                </Button>
                            </Grid>
                            <ToastContainer />
                        </AnimateButton>
                    );
                }
            }
        }
    ];

    return (
        <MainCard title="Influencers">
            {load ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <BeatLoader color="#e1c318" />
                    </div>
                </>
            ) : (
                <>
                    <Dialog open={paymentIsOpen} onClose={() => setPaymentIsOpen(!paymentIsOpen)}>
                        <DialogTitle id="alert-dialog-title">Record Payment</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Enter the Payment made for this Influencer</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="payment"
                                label="Payment"
                                type="number"
                                value={payment}
                                onChange={(e) => setPayment(e.target.value)}
                                fullWidth
                                variant="standard"
                                sx={{ input: { color: 'white' } }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePayment}>Record</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={modalIsOpen} onClose={() => setIsOpen(!modalIsOpen)}>
                        <DialogTitle id="alert-dialog-title">Award Tickets</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Enter Number of Tickets for this Influencer</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="tickets"
                                label="Tickets"
                                type="number"
                                value={tickets}
                                onChange={(e) => setTickets(e.target.value)}
                                fullWidth
                                variant="standard"
                                sx={{ input: { color: 'white' } }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAssign}>Assign</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={deletemodalIsOpen} onClose={() => setdeleteIsOpen(!deletemodalIsOpen)}>
                        <DialogTitle id="alert-dialog-title">Delete Influencer</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Do you want to delete this influencer:</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>Delete</Button>
                            <Button onClick={() => setdeleteIsOpen(!deletemodalIsOpen)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={usermodalIsOpen} onClose={() => setUserIsOpen(!usermodalIsOpen)}>
                        <DialogTitle id="alert-dialog-title">Make User</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Do you want to make this influencer a user?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleMakeUser}>Revert to User</Button>
                            <Button onClick={() => setUserIsOpen(!usermodalIsOpen)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>

                    <div style={{ height: 'auto', width: '100%' }}>
                        <ThemeProvider theme={getMuiTheme}>
                            <MUIDataTable data={allInfluencers} columns={columns} options={options} />
                        </ThemeProvider>
                    </div>
                </>
            )}
            <ToastContainer />
        </MainCard>
    );
};

export default Influencers;

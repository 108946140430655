import {
    AUTH_REQUEST,
    AUTH_REQUEST_FAILED,
    LOGIN_USER,
    SIGN_UP,
    ACCOUNT_CHECK,
    FORGOT_PASS,
    VERIFY_OTP,
    RESET_PASS,
    LOGOUT
} from '../constants/auth-constants';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_REQUEST:
            return {
                ...state,
                isLoggedIn: false
            };
        case LOGIN_USER:
            return {
                ...state,
                isLoggedIn: true,
                user: payload
            };
        case AUTH_REQUEST_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case SIGN_UP:
            return {
                ...state,
                isLoggedIn: false
            };
        case ACCOUNT_CHECK:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case FORGOT_PASS:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case VERIFY_OTP:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case RESET_PASS:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        default:
            return state;
    }
};
